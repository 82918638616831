import * as React from "react";
import { Fade } from "react-awesome-reveal";
import Marquee from "react-fast-marquee";
import Container from "./styled";

export default function Logos({ landing }) {
  return (
    <Container>
      {/* Logos Container */}
      {landing != "federacion" && (
        <div className="logos-container opacidad08">
          {/* Logo */}
          <img
            alt="Federación Patronal Seguros Logo"
            src="/logos/aseguradoras/federacionpatronal.svg"
            id="patronal-logo"
          ></img>
        </div>
      )}
      {landing != "federacion" ? (
        <Fade className="fade-container-marquee" delay={50} duration={700}>
          <Marquee gradient={true} speed="45">
            {/* Logo */}
            <img
              alt="Federación Patronal Seguros Logo"
              src="/logos/aseguradoras/federacionpatronal.svg"
              id="patronal-logo"
            ></img>
            {/* Logo */}
            <img
              alt="Federación Patronal Seguros Logo"
              src="/logos/aseguradoras/federacionpatronal.svg"
              id="patronal-logo"
            ></img>
          </Marquee>
        </Fade>
      ) : (
        <div className="federacion-container opacidad08">
          {/* Logo */}
          <img
            alt="Federación Patronal Seguros Logo"
            src="/logos/aseguradoras/federacionpatronal.svg"
            id="patronal-logo"
          ></img>
        </div>
      )}
    </Container>
  );
}
